<template>
  <div>
    <!-- 欠费 补缴页面支付 -->
    <van-nav-bar
      class="backBox"
      title="欠费订单支付"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="container">
      <div
        class="escapeListBox"
        v-for="(item, index) in escDataList"
        :key="index"
      >

        <div class="boxTop">
          <div class="row1 tit">
            <div class="number">
              <span class="normal">{{ item.plate_number }}</span>
            </div>
          </div>
          <div class="escPrice">
            <span class="couponPrice"
              >{{ (item.fee_value / 100).toFixed(2) }}
              <span style="font-size: 0.14rem">元</span></span
            >
          </div>
          <div class="escPrice">
            <span class="tit">欠费订单</span>
          </div>
          <div class="itemText">
            <span class="time-tip">车场全称:</span>
            <span class="in-time">{{ parkName }}</span>
          </div>
          <div class="itemText">
            <span class="time-tip">订单号:</span>
            <span class="in-time">{{ item.fee_order_sn }}</span>
          </div>
          <div class="itemText">
            <span class="time-tip">入场时间:</span>
            <span class="in-time">{{ formatDate(item.in_time) }}</span>
          </div>
          <div class="itemText">
            <span class="time-tip">出场时间:</span>
            <span class="in-time">{{ formatDate(item.out_time) }}</span>
          </div>
          <div class="itemText">
            <span class="time-tip">停车时长:</span>

            <span class="in-time">{{ item | formatParkTime }} </span>
          </div>
          <div class="itemText">
            <span class="time-tip">入场车道:</span>
            <span class="in-time">{{ item.in_channel_name }}</span>
          </div>
          <div class="itemText">
            <span class="time-tip">出场车道:</span>
            <span class="in-time">{{ item.out_channel_name }}</span>
          </div>

          <div class="itemText">
            <span class="time-tip">出入场照片:</span>
            <span class="in-time viewImg" @click="openInOutImg(item)"
              >查看详情</span
            >
          </div>
        </div>

        <div class="boxBottom">
          <div class="comment">
            <span class="time-tip">备注:</span>
            <span class="in-time">{{ item.cancel_comment }}</span>
          </div>

          <div class="button-row-pay">
            <van-button
              class="escBtn"
              type="info"
              size="large"
              @click="confirmEscPay(item)"
              >补缴</van-button
            >
          </div>
        </div>
      </div>

      <!-- 查看照片 -->
      <van-dialog
        v-model="showImg"
        title="进出场照片详情"
        confirm-button-color="#1989fa"
      >
        <div style="text-align: center; margin-top: 3%" v-if="isLoadingImg">
          <van-loading color="#1989fa" text-color="#999" vertical
            >图片加载中，请稍后...</van-loading
          >
        </div>
        <div class="imgBox" v-for="item in inoutImgs" :key="item.id" style="">
          <div class="imgItem">
            <van-image width="260" height="" :src="item.url" fit="cover">
              <template v-slot:default>
                <div style="text-align: center; font-size: 0.12rem">
                  {{ item.text }}
                </div>
              </template>
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
        </div>
      </van-dialog>
      <div class="escapeListEmptyBox" v-show="escDataList.length === 0">
            <van-empty description="暂无欠费订单" />

      </div>

      <div class="banner" v-if="!isCloseAD">
        <adver urls="744202053102985216"></adver>
      </div>
    </div>
    <div v-if="!isTypeReady" class="mask_layout" @click.stop></div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import * as mqtt from '../../api/mqtt/wsconnect'
import * as util from '../../api/util'
import * as common from '../../api/common'
import * as constant from '../../api/constant'
import * as api from '../../api/api'
// import { Toast } from 'vant'
import Adver from '../../components/Adver.vue'

export default {
  name: 'pay_escape',
  components: {
    Adver
  },
  data () {
    return {
      parkName: '',
      showImg: false,
      inOrOut: '',
      // inImg: 'https://img01.yzcdn.cn/vant/apple-3.jpg',
      inImg: '',
      outImg: '',
      vh_out_id: '',
      inoutImgs: [],
      isLoadingImg: false,
      // add
      btn_del: false,
      // sid: '',
      plate_number: '',
      car_type: '',
      gross_price: 0,
      actually_paid: 0,
      payablefee: 0,
      start_time: '',
      end_time: '',
      in_time: '',
      hour: '',
      min: '',
      seconds: '',
      sign: '',
      req_order_sn: '',
      code: '',
      appidLocal: '', // 当前sid对应的appid
      selectType: [],
      selectid: [],
      selectRuleId: [],
      Price: '',
      couponPrice: '',
      couponPrice1: '',
      visible: false,
      couponType: {
        money: 1, // 减免金额
        time: 2, // 减免时间
        disBreaks: 3, // 打折减免
        free: 4 // 全免
      },
      list: [],
      isVueReady: false,
      isTypeReady: false,
      isEmqxConnect: false,
      isPress: false,
      escapeOrder: '',
      pay_channel_platform: '',
      pay_channel: '',
      localInfo: {},
      escDataList: [],
      isCloseAD: false
    }
  },
  computed: {
    ...mapState({
      plateNumber: state => state.plateNumber.plateNumber,
      adverInfo: state => state.adverInfo.adverInfo,
      payType: state => state.payType.payType,
      sid: state => state.sid.sid,
      saveRouteQueryData: state => state.saveRouteQueryData.saveRouteQueryData,
      appid: state => state.appid.appid,
      codeLocal: state => state.code.code
    })
  },
  created () {
  },
  mounted () {
    this.queryADConfig()
    const escData = JSON.parse(this.$route.query.escData)
    const localInfo = JSON.parse(this.$route.query.localInfo)
    this.localInfo = localInfo
    this.escDataList = escData.list
    this.pay_channel = this.$route.query.pay_channel

    this.query_customer_by_sid()
    const that = this
    this.isVueReady = true
    if (common.getUserAgent() === 'wx') {
      this.WX_Bridge_ready(() => {
        setButtonClertload()
      })
    } else if (common.getUserAgent() === 'zfb') {
      this.ZFBReady(() => {
        setButtonClertload()
      })
    }

    function setButtonClertload () {
      that.$nextTick(() => {
        that.isTypeReady = true
      })
    }
  },
  methods: {
    // 获取关闭广告配置
    queryADConfig () {
      const flag = util.judgeADClose({}, 2, 2)
      this.isCloseAD = flag
    },
    unsubscribe: function (action, callback) {
      // 订阅
      const topic = `/ypt/u_${this.sid}/${encodeURIComponent(
        this.plate_number
      )}`
      const options = {
        payload: {
          topic: action,
          plate_number: this.plate_number
        }
      }
      mqtt.unsubscribe(topic, options, function (err, granted) {
        if (err) {
          callback(err, granted)
          return
        }
        callback(null, granted)
      })
    },
    confirmEscPay: async function (row) {
      let payWay = common.payWay.weichat
      if (common.getUserAgent() === 'wx') {
        payWay = common.payWay.weichat
      } else if (common.getUserAgent() === 'zfb') {
        payWay = common.payWay.alipay
      }

      this.tempQueryParams = {
        plate_number: row.vh_p_number,
        gross_price: parseFloat(row.fee_value),
        actually_paid: parseFloat(row.fee_value),
        req_order_sn: row.req_order_sn,
        start_time: row.start_time,
        end_time: row.end_time,
        sign: this.sign,
        hour: this.hour,
        min: this.min,
        seconds: this.seconds,
        local_info: this.localInfo,
        sid: this.sid,
        appid: this.appid,
        auth_code: this.$route.query.auth_code
      }
      if (!this.codeLocal) {
        this.get_open_url(
          payWay,
          this.sid,
          this.localInfo.plate_number,
          this.tempQueryParams
        ).then((openUrl) => {
          this.getAllCode()
        })
      }

      var s1 = new Date(row.start_time).getTime('yyyy-MM-dd hh:mm:ss')
      var s2 = new Date(row.end_time).getTime('yyyy-MM-dd hh:mm:ss')
      var total = (s2 - s1) / 1000
      var hour = parseInt(total / (60 * 60))
      var afterHour = total - hour * 60 * 60 // 取得算出小时数后剩余的秒数
      var min = parseInt(afterHour / 60)
      var seconds = total - hour * 60 * 60 - min * 60

      this.hour = hour
      this.min = min
      this.seconds = seconds

      this.sign = this.$route.query.sign

      //
      this.start_time = row.start_time
      this.end_time = row.end_time
      this.plate_number = row.vh_p_number
      // 欠费金额
      this.gross_price = row.fee_value
      // 需要缴纳金额
      this.actually_paid = row.fee_value
      this.isPress = true
      if (parseFloat(this.gross_price) <= 0) {
        this.$toast('收费 0 元')
        // return
      }

      const payType = this.payType
      const that = this

      try {
        this.payType = payType

        if (!this.isVueReady) {
          return this.$toast('努力加载中.')
        } else if (!this.isTypeReady) {
          if (common.getUserAgent() === 'wx') {
            return this.$toast('微信努力加载中.')
          } else {
            return this.$toast('支付宝努力加载中.')
          }
        } else if (!this.isEmqxConnect) {
          doPay(row)
        } else {
          doPay(row)
        }
        // return false
      } catch (e) {
        console.log(e)
        this.$toast.fail('请求失败')
      }

      function doPay (row) {
        that.$toast.loading({
          mask: true,
          message: '请稍候...',
          duration: 5000
        })

        const localInfo = {
          ...row,
          sid: that.sid,
          channel_path: row.channel_path ? row.channel_path : -1,
          vh_p_number: row.plate_number,
          vh_pnull_number: row.plate_number,
          payType: that.payType,
          pay_channel: that.pay_channel ? that.pay_channel : -1
        }

        const obj = {
          sid: that.sid,
          gross_price: parseFloat(row.fee_value),
          actually_paid: parseFloat(row.fee_value),
          sign: that.sign,
          code: that.codeLocal || 'invalid',
          plate_number: row.plate_number,
          subject:
            (that.parkName ? that.parkName + ' ' : '') +
            row.plate_number +
            '停车收费',
          start_time: common.format(new Date(row.start_time), 'yyyy-MM-dd hh:mm:ss'),
          end_time: common.format(new Date(row.end_time), 'yyyy-MM-dd hh:mm:ss'),
          local_info: localInfo,
          is_xcx: false
        }

        common
          .getPayargs(
            obj,
            null,
            that.payType,
            that.localInfo.pay_channel_platform
          )
          .then((payargs) => {
            that.$toast.clear()
            that.selectid = []
            that.selectType = []
            that.selectRuleId = []

            if (parseFloat(that.actually_paid) <= 0) {
              that.isPress = true
              that.$toast({
                type: 'success',
                message: '支付0元',
                duration: 3000,
                forbidClick: true
              })
              setTimeout(function () {
                if (common.getUserAgent() === 'wx') {
                  // eslint-disable-next-line no-undef
                  WeixinJSBridge.call('closeWindow')
                } else if (common.getUserAgent() === 'zfb') {
                  // eslint-disable-next-line no-undef
                  ap.popWindow()
                }
              }, 3000)
            } else {
              that.isPress = false
              if (common.getUserAgent() === 'wx') {
                that.wxReadyToPay(payargs)
              } else if (common.getUserAgent() === 'zfb') {
                that.do_zfb_pay(payargs)
              }
            }
          })
          .catch((e) => {
            // that.$toast.clear();
            console.log('error', e)
            that.isPress = false
            if (parseFloat(that.actually_paid) > 0) { that.$toast.fail('支付失败') } else that.$toast.clear()
          })
      }
    },
    ZFBReady (callback) {
      if (window.AlipayJSBridge) {
        callback && callback()
      } else {
        if (document.addEventListener) {
          document.addEventListener('AlipayJSBridgeReady', callback, false)
        } else if (document.attachEvent) {
          document.attachEvent('AlipayJSBridgeReady', callback)
        }
      }
    },
    do_zfb_pay (payargs) {
      const that = this
      this.payargs = payargs
      this.ZFBReady(function () {
        const payType = that.payType
        if (payType === common.payType.zfb) {
          // 支付宝直联
          // eslint-disable-next-line no-undef
          ap.tradePay(
            {
              tradeNO: payargs.tradeNo
            },
            function (res) {
              if (res && res.resultCode === 9000) {
                // eslint-disable-next-line no-undef
                AlipayJSBridge.call('pushWindow', {
                  url: 'https://pa03.yuntingiot.com/api/ac/477786592149311488',
                  param: {
                    backBehavior: 'pop',
                    closeCurrentWindow: true
                  }
                })
              } else {
                that.$toast.fail('支付失败')
              }
            }
          )
        } else if (payType === common.payType.bjyz_zfb) {
          // 支付宝云纵间联

          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          that.$store.commit(constant.keyAdverInfoVuex, pars)
          that.isPress = true
          window.location.replace(payargs.trade_url)
        } else if (payType === common.payType.pp_zfb) {
          // 支付宝PP间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          that.$store.commit(constant.keyAdverInfoVuex, pars)

          window.location.replace(payargs.pay_url)
        } else if (payType === common.payType.abc_zfb) {
          // 支付宝农行间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          that.$store.commit(constant.keyAdverInfoVuex, pars)

          window.location.replace(payargs.pay_url)
        } else if (payType === common.payType.lanzhoubank_zfb) {
          // 支付宝农行间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            // invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          that.$store.commit(constant.keyAdverInfoVuex, pars)

          window.location.replace(payargs.billQRCode)
        } else if (payType === common.payType.ningbobank_zfb) {
          // 支付宝宁波银行间联
          const pars = {
            start_time: that.start_time,
            hour: that.hour,
            min: that.min,
            seconds: that.seconds,
            actually_paid: that.actually_paid,
            couponPrice1: that.couponPrice1,
            // invoice_url: payargs.invoice_url,
            sid: that.sid,
            parkName: that.parkName,
            plateNumber: that.plate_number
          }
          that.$store.commit(constant.keyAdverInfoVuex, pars)

          window.location.replace(payargs.pay_url)
        }
      })
    },
    async get_open_url (payWay, sid, plateNumber, tempQueryParams) {
      const local = common.cloudPayUrl + '/#/payEscape'
      return axios({
        url: common.cloudPayUrl + '/api/' + api.urlList.get_openid_url,
        method: 'POST',
        data: {
          pay_way: payWay,
          sid: sid,
          plate_number: plateNumber,
          tempQueryParams: tempQueryParams,
          redirect_url: local
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
        .then(res => {
          const d = res && res.data
          if (d.code === 10000) {
            this.$store.commit(constant.keyPayTypeVuex, d.pay_type)
            return d.open_url
          } else {
            return Promise.reject(new Error(res.msg))
          }
        })
    },
    onClickLeft () {
      let saveRouteQueryDataLocal
      if (sessionStorage.getItem(constant.keySaveRouteQueryDataVuex)) {
        saveRouteQueryDataLocal = JSON.parse(sessionStorage.getItem(constant.keySaveRouteQueryDataVuex))
      }
      this.$router.replace({
        path: '/payMoney',
        query: {
          plate_number: saveRouteQueryDataLocal.plate_number,
          gross_price: saveRouteQueryDataLocal.gross_price,
          req_order_sn: saveRouteQueryDataLocal.req_order_sn,
          start_time: saveRouteQueryDataLocal.start_time,
          end_time: saveRouteQueryDataLocal.end_time,
          sign: saveRouteQueryDataLocal.sign,
          localInfo: saveRouteQueryDataLocal.localInfo,
          escape_info: saveRouteQueryDataLocal.escape_info,
          pay_channel_platform: saveRouteQueryDataLocal.pay_channel_platform,
          receive_timestamp: saveRouteQueryDataLocal.receive_timestamp
        }
      })
    },
    async getAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        const d = res && res.data
        if (d.code === 10000) {
          this.appidLocal = d.app_id
          this.$store.commit(constant.keyAppIDVuex, d.app_id)
          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },
    getZFBAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_zfb_appid,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        const d = res && res.data
        if (d.code === 10000) {
          this.appidLocal = d.auth_app_id
          this.$store.commit(constant.keyAppIDVuex, d.app_id)
          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },

    onBridgeReady (payargs) {
      var that = this

      const payType = this.payType
      if (payType === common.payType.wx) {
        // 微信直联
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: payargs.appId !== null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType !== null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              window.location.replace(
                'https://pa03.yuntingiot.com/api/ac/477786592149311488'
              )
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$toast.fail('支付失败')
            }
          }
        )
      } else if (payType === common.payType.bjyz) {
        // return false;
        // 微信云纵间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          invoice_url: payargs.invoice_url,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.trade_url)
        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.pp) {
        // 微信PP间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: -1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.abc) {
        // 微信农行间联
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true

        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: payargs.appId !== null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType !== null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示
              window.location.replace(
                'https://pa03.yuntingiot.com/api/ac/477786592149311488'
              )
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$toast.fail('支付失败')
            }
          }
        )
      } else if (payType === common.payType.lanzhoubank) {
        // 兰州银行微信
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.billQRCode)
      } else if (payType === common.payType.ningbobank) {
        // 宁波银行微信
        const pars = {
          start_time: this.start_time,
          hour: this.hour,
          min: this.min,
          seconds: this.seconds,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }
        this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
      }
    },
    wxReadyToPay (payargs) {
      var that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener(
            'WeixinJSBridgeReady',
            that.onBridgeReady(payargs),
            false
          )
        } else if (document.attachEvent) {
          document.attachEvent(
            'WeixinJSBridgeReady',
            that.onBridgeReady(payargs)
          )
          document.attachEvent(
            'onWeixinJSBridgeReady',
            that.onBridgeReady(payargs)
          )
        }
      } else {
        that.onBridgeReady(payargs)
      }
    },
    strToDate (dateObj) {
      if (dateObj) {
        dateObj = dateObj
          .replace(/T/g, ' ')
          .replace(/\.[\d]{3}Z/, '')
          .replace(/(-)/g, '/')
        if (dateObj.indexOf('.') > 0) { dateObj = dateObj.slice(0, dateObj.indexOf('.')) }
        return new Date(dateObj)
      } else {
        return ''
      }
    },
    query_customer_by_sid () {
      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_customer,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        const d = res && res.data
        if (d.code === 10000) {
          var str = d.list[0].alias_name
          var lastTwo = str.slice(str.length - 2, str.length)
          if (lastTwo === '车场') {
            this.parkName = d.list[0].alias_name
          } else {
            this.parkName = d.list[0].alias_name + '车场'
          }
        }
      })
    },
    // 订阅车辆进出场详情
    subscribeInOutDetail: async function (action, callback) {
      console.log('订阅', action, callback)
      // 订阅
      const topic = `/ypt/u_${this.sid}/mq_query_inout_detail`
      const options = {
        payload: {
          topic: 'mq_query_inout_detail'
        }
      }
      await mqtt.subscribe(topic, options)
    },
    // 查看出入场照片getInoutDetail
    async getInoutDetail () {
      // this.inOrOut = 'OUT';

      var that = this
      // that.$toast.loading({
      //   mask: true,
      //   message: '图片加载中，请稍候...',
      //   duration: 3000,
      // });
      this.isLoadingImg = true

      try {
        await that.init_mqtt_inoutDetail()
        await that.subscribeInOutDetail('sq')
      } catch (e) {
        console.log(e)
        // this.$toast.fail(e)
        throw e
      }
    },
    async init_mqtt_inoutDetail (cb) {
      var that = this
      // var utils = util
      const config = {
        onConnected: function () {
          that.isEmqxConnect = true
        },
        // onReconnect: that.subscribe,
        onMessage: this.getInoutDetailResult
      }
      const client = await mqtt.getClient(config)
      if (client && client.connected) {
        client.callback.mq_query_inout_detail = this.getInoutDetailResult
        return client
      } else {
        that.$toast.fail('NetWork err!')
        throw new Error('client NetWork err!')
      }
    },
    getInoutDetailResult: async function (topic, message, packet) {
      const that = this
      // const utils = util
      this.isLoadingImg = false

      that.inoutImgs = []
      that.inoutImgs.push({
        id: '0',
        direction: 'in',
        url: message.in_img,
        text: '进场图片'
      })
      that.inoutImgs.push({
        id: '1',
        direction: 'out',
        url: message.img,
        text: '出场图片'
      })

      if (message && message.code !== common.RespMsg.OK.code) {
        return that.$toast(message.msg || '查看照片出错')
      }
    },
    // 1 点击查询动作
    async openInOutImg (row) {
      if (this.inoutImgs.length > 0 && this.vh_out_id === row.vh_out_id) {
        this.showImg = true
        return false
      }

      this.inoutImgs = []

      try {
        // 等待订阅操作
        await this.getInoutDetail()
      } catch (e) {
        this.$toast.fail('查询照片出错')
        return
      }

      axios({
        url: common.iotUrl + '/s2p/' + api.urlList.query_in_out_detail,
        method: 'POST',
        data: {
          sid: this.sid,
          in_out_id: row.vh_out_id,
          version: 'v2',
          access_token: 'CA502D23-BB70-0001-55FA-1B501C501A6B',
          app_key: '666a659e-244d-46b1-a8cc-3c5190c3823a'
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        console.log('请求查看照片返回信息', res)
        if (res.data.code && res.data.code === 10000) {
          this.vh_out_id = row.vh_out_id
          this.showImg = true

          this.visible = false
        } else {
          this.$toast(common.resMSG[res.data.code])
        }
      })
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    getAllCode () {
      if (this.code) {
        return
      }
      if (common.getUserAgent() === 'wx') {
        this.getAppId()
          .then((appid) => {
            window.location.href = common.getCodeObtainUrl(window.location, appid, true)
          })
          .catch((e) => {
            this.$toast('网络错误')
          })
      } else if (common.getUserAgent() === 'zfb') {
        this.appid = this.$route.query.app_id
        this.code = this.$route.query.auth_code
        if (!this.code) {
          this.getZFBAppId()
            .then((appid) => {
              window.location.href = common.getCodeObtainUrl(window.location, appid, false)
            })
            .catch((e) => {
              this.$toast('网络错误')
            })
        }
      }
    },
    formatDate (date) {
      return common.format(new Date(date), 'yyyy-MM-dd hh:mm:ss')
    }

  },
  filters: {
    filterVH (num) {
      const reg = /^N.*/
      if (reg.test(num)) {

      }
    },
    filterClass (num) {
      const reg = /^N.*/
      if (num.length < 8) {
      }
      if (reg.test(num)) {

      }
    },
    filterTimeIn (time) {
      try {
        if (time) {
          const formatTime = common.format(new Date(time), 'yyyy-MM-dd hh:mm:ss')
          return formatTime
        } else {
          return '无入场时间'
        }
      } catch (e) {
        return '不支持的时间格式'
      }
    },
    filterTimeOut (time) {
      try {
        if (time) {
          // const formatTime = common.format(time).format('yyyy-MM-dd hh:mm:ss')
          const formatTime = common.format(new Date(time), 'yyyy-MM-dd hh:mm:ss')
          return formatTime
        } else {
          return '无出场时间'
        }
      } catch (e) {
        return '不支持的时间格式'
      }
    },
    formatParkTime (item) {
      try {
        if (item.start_time && item.end_time) {
          // 计算停车时长
          var s1 = new Date(item.start_time).getTime('yyyy-MM-dd hh:mm:ss')
          var s2 = new Date(item.end_time).getTime('yyyy-MM-dd hh:mm:ss')
          //  var s1= new Date("2023-06-02T09:48:16+08:00").format('yyyy-MM-dd hh:mm:ss')
          //  var s2= new Date("2023-06-02T09:48:36+08:00").format('yyyy-MM-dd hh:mm:ss')
          // // var s2=""
          var total = (s2 - s1) / 1000
          var hour = parseInt(total / (60 * 60))
          var afterHour = total - hour * 60 * 60 // 取得算出小时数后剩余的秒数
          var min = parseInt(afterHour / 60)
          var seconds = total - hour * 60 * 60 - min * 60

          const formatParkTimes = hour + '时' + min + '分' + seconds + '秒'
          // return common.format(time, 'yyyy-MM-dd hh:mm:ss');
          return formatParkTimes
        } else {
          // return '无入场时间';
        }
        // })
      } catch (e) {
        // return '不支持的时间格式';
      }
    }
  },
  beforeDestroy () {
    this.unsubscribe('sq', function (err, granted) {
      console.log('退订', err, granted)
      mqtt.close()
    })
  }
}
</script>
<style scoped>
.container {
  /* margin-top: .22rem; */
  padding: 0.15rem 0.16rem 0;
  background-color: #f8f7fc;
}
.escapeListEmptyBox{
  padding: 0.16rem;
  background-color: #fff;

}
.mask_layout {
  z-index: 9999;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.escapeListBox {
  /* border: 1px solid gray; */
  /* padding: 0.1rem; */
  /* margin-top: 0.1rem; */
  /* background-color: #fff; */
  /* margin-top: 0.1rem; */
  margin-bottom: 0.16rem;
}

.escapeListBox .boxTop {
  padding: 0.1rem 0.1rem 0 0.1rem;
  background-color: #fff;
}
.escapeListBox .boxBottom {
  padding: 0 0.1rem 0.1rem 0.1rem;
  background-color: #fff;
}

.row1 {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
}

.row1 .number {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 0.22rem;
  font-weight: bold; */
  /* margin-right: .06rem; */
}
.row1 .ddh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* font-size: 0.14rem; */
  margin-top: 0.06rem;
  /* font-weight: bold; */
  /* margin-right: .06rem; */
}

.row1 .normal {
  /* max-width: 180px; */
  font-size: 0.14rem;
  color: #333;
  /* margin-left: 0.06rem; */
  line-height: 0.2rem;

  /* padding: 0.01rem 0.04rem; */
  /* border-radius: 0.12rem; */
  /* font-weight: normal; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

.row1 .special {
  /* font-size: 0.16rem; */
  padding: 0.01rem 0.04rem;
  color: #7eab4c;
  background: #e9f9dc;
  border-radius: 0.12rem;
}

.row2 {
  margin-top: 0.36rem;
  text-align: center;
  /* font-size: 0.16rem; */
}

.row3 {
  margin-top: 0.12rem;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.row3 .code {
  /* font-size: 0.28rem; */
  color: #333;
  font-weight: bold;
  margin-right: 0.06rem;
}

.row3 .number {
  /* font-size: 0.42rem; */
  color: #333;
  font-weight: bold;
}

.itemText {
  /* color: #999 !important; */
  /* font-size: 0.14rem !important; */
  margin-top: 0.08rem;
  /* 1rem=100px */
  height: 0.2rem;
  line-height: 0.2rem;
  /* height: 35px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 0.01rem solid #ededed; */
  /* border-bottom: 0.01rem solid #ededed; */
}

.itemText .time-tip,
.itemText .in-time {
  font-size: 0.14rem !important;
  /* font-weight: lighter !important; */
  color: #999 !important;
  /* font-family: PingFangSC-Regular, sans-serif; */
}

.itemText .in-time {
  font-size: 0.14rem !important;
  /* font-weight: lighter !important; */
  color: #333 !important;
  /* font-family: PingFangSC-Regular, sans-serif; */
}

.imgBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
}
.imgBox .imgItem {
  /* width: 65%;
  margin-bottom: 3%; */
}

.imgBox img {
  width: 100%;
  height: 100%;
}
/* .row4 .time-tip {
  color: #999;
  font-size: 0.14rem;
} */
/*
.row4 .in-time {
  color: #999;
  font-size: 0.14rem;
}

.row5,
.row6 {
  margin-top: 0;
  border-top: none;
} */

.escPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-top: 0.1rem; */
}
.escPrice > .couponPrice {
  line-height: 0.25rem;
  /* text-align: center; */
  font-size: 0.2rem;
  font-weight: bold;
  /* color: rgba(238, 38, 10, 1); */
  color: #333;
  /* font-family: PingFangSC-Medium, sans-serif !important; */
}
.escPrice > .tit {
  /* text-align: center; */
  font-size: 0.12rem;
  line-height: 0.17rem;
  /* font-weight: bold; */
  color: #333;
}
.itemText > .viewImg {
  /* font-size: 0.14rem; */
  color: #1989fa !important;
}

.boxBottom {
  font-size: 0.14rem !important;
  color: #999 !important;
}
.boxBottom .comment {
  /* margin-top: 0.08rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-family: PingFangSC-Regular, sans-serif; */
  padding-top: 0.1rem;
}
.comment > .in-time {
  display: flex;
  max-width: 250px;
  text-align: right;
  color: #333;
  /* white-space: pre-wrap; */
  /* overflow: hidden;
  text-overflow: ellipsis; */
}

.button-row-pay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding-top: 10px; */
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
  /* font-family: PingFangSC-Regular, sans-serif; */
}
.escBtn {
  width: 1.6rem;
  height: 0.48rem;
}
/* ::v-deep .van-dialog__footer {
  margin-top: 5% !important;
} */

.van-divider {
  margin: 8px 0;
  border-color: gray;
}
</style>
